import React from "react";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";

import Navbar from "components/Navbar";
import Footer from "components/Footer";

import News1 from "components/news/news1";

import { live } from "./content";

import ScrollUpButton from "react-scroll-up-button";

function News({ match }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  const News = match.params.id;
  const n = live.find((x) => x.link === News);
  return (
    <div
      style={{
        // background: "#000",
        backgroundImage: "url(" + require("assets/images/services_bg.jpg") + ")",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
      <Navbar />
      <ScrollUpButton />
      <section id="" />
      <ProfilePageHeader title={n.description} />
      {n.id === 1 && <News1 news={n} />}

      <Footer />
    </div>
  );
}

export default News;
