// reactstrap components
import { Container, Col, Row, Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import React, { useState } from "react";
import AnimeBackground from "components/Anime";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  React.useEffect(() => {
    const common = {
      containment: "#bgndVideo1",
      autoPlay: true,
      mute: true,
      startAt: 0,
      opacity: 1,
      loop: false,
      ratio: "4/3",
      addRaster: true,
    };

    const videos = [
      {
        videoURL: "7o3nrxajtzk",
        ...common,
      },
    ];
    if (size) {
      window.jQuery("#bgndVideo1").YTPlaylist(videos, false);
    }
  }, []);
  const [modalRegister, setModalRegister] = useState(false);
  const toggleRegister = () => setModalRegister(!modalRegister);
  return (
    <>
      <div
        className="page-header"
        style={{
          backgroundColor: "transparent",
        }}
        data-parallax={true}
      >
        {size && (
          <div
            style={{
              background: "#000",
              position: "absolute",
              top: 0,
              width: "100%",
              zIndex: -100,
              marginLeft: "auto",
              marginRight: "auto",
              pointerEvents: "none",
              // marginTop: "3.5rem",
            }}
          >
            <div
              id="bgndVideo1"
              className="section player"
              style={{ background: "transparent" }}
            ></div>
          </div>
        )}
        {size && <div className="overlay-primary" />}
        <Container fluid style={{ alignSelf: "flex-end", marginBottom: size ? "8rem" : 0 }}>
          <Row className="justify-content-start">
            <Col md={8} xs={12}>
              {/* <img src={require("assets/images/logo/Logo.png")} width="100%" alt="main logo" /> */}
              <h3
                style={{
                  color: "#fff",
                  fontSize: size ? "42px" : "2rem",
                  marginTop: size ? 0 : 20,
                }}
                className="text-700 ml-2"
              >
                The Leading Summit on Financial Services Innovation and Excellence
              </h3>
            </Col>
            <Col md={3} xs={12} className={size ? "border-left" : "border-top"}>
              <h2
                className="text-700 text-i ml-2 mt-3 mb-2"
                style={{
                  color: "#fff",
                  fontSize: size ? "40px" : "1.5rem",
                }}
              >
                April <span style={{ color: "#40b5cb" }}>23</span>, 2025
              </h2>
              <h4
                style={{
                  color: "#fff",
                  fontSize: size ? "30px" : "1.5rem",
                }}
                className="text-700 mt-3 ml-2"
              >
                Cairo, Egypt
              </h4>
            </Col>
            <Col md={6} xs={12} className="mt-4">
              <Button
                href="/register"
                className="navbar-item-custom text-400 text-dark rounded-0 ml-2"
                color="primary"
                size="lg"
              >
                <span
                  style={{
                    color: "#ffffff",
                    fontSize: "16px",
                  }}
                  className="text-700"
                >
                  REGISTER NOW
                </span>
              </Button>{" "}
              <Button
                href="/sponsor"
                className="navbar-item-custom text-400 text-dark rounded-0"
                size="lg"
                color="primary"
              >
                <span
                  style={{
                    color: "#ffffff",
                    fontSize: "16px",
                  }}
                  className="text-700"
                >
                  BECOME A SPONSOR
                </span>
              </Button>
            </Col>
          </Row>
        </Container>

        <AnimeBackground />
      </div>
      <Modal isOpen={modalRegister} toggle={toggleRegister} className="modal-dialog-centered">
        <ModalHeader toggle={toggleRegister}>
          <b>REGISTER FOR FINNOVEX NORTH AFRICA</b>
        </ModalHeader>
        <ModalBody>
          <iframe
            id="ts-iframe"
            src="https://www.townscript.com/v2/widget/22nd-edition-finnovex-north-africa-2024-003144/booking"
            frameborder="0"
            height="600"
            width="100%"
            title="townscript"
          ></iframe>
          <link
            rel="stylesheet"
            href="https://www.townscript.com/static/Bookingflow/css/ts-iframe.style.css"
          />
        </ModalBody>
      </Modal>
    </>
  );
}

export default LandingPageHeader;
